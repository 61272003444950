import React from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"
import SchoolItemAboutSection from "../../sections/schools/SchoolItemAboutSection"
import Layout from "../../components/Layout"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"

const SchoolPageAbout = props => {
  const schoolsSelect = props.pageContext.schools
    ? props.pageContext.schools
    : props.pageContext.summerSchools
  return (
    <Layout
      wrapperClass={"school-item about-school-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state2="active"
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        school={props.pageContext.currentSchool}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemAboutSection school={props.pageContext.currentSchool} />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={schoolsSelect}
      />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageAbout
