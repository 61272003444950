import React from "react"
import SchoolTextSection from "./SchoolTextSection"
import { useTranslation } from "react-i18next"

const SchoolItemAboutSection = props => {
  const data = props.school || {}
  const { t } = useTranslation()

  return (
    <div className="white-block about-school-block">
      {props.school.isSummer ? (
        <div className="about-school-content-wrapper container f-js">
          <SchoolTextSection
            title={t("school.about.tuition")}
            text={data.tuition}
          />
          <SchoolTextSection
            title={t("school.about.boarding")}
            text={data.boarding}
          />
          <SchoolTextSection
            title={t("school.about.activities")}
            text={data.activities}
          />
        </div>
      ) : (
        <div className="about-school-content-wrapper container f-js">
          <SchoolTextSection
            title={t("school.about.sellingPoints")}
            text={data.uniqueSellingPoints}
          />
          <SchoolTextSection
            title={t("school.about.history")}
            text={data.history}
          />
          <SchoolTextSection
            title={t("school.about.location")}
            text={data.location}
          />
          <SchoolTextSection
            title={t("school.about.teaching")}
            text={data.teaching}
          />
          <SchoolTextSection
            title={t("school.about.boarding")}
            text={data.boarding}
          />
          <SchoolTextSection
            title={t("school.about.sports")}
            text={data.activities}
          />
        </div>
      )}
    </div>
  )
}

export default SchoolItemAboutSection
